import {
    Component,
    ViewEncapsulation,
    Inject,
    OnInit,
    OnDestroy,
    ChangeDetectionStrategy,
    signal
} from '@angular/core';
import {
    MatBottomSheet,
    MAT_BOTTOM_SHEET_DATA
} from '@angular/material/bottom-sheet';
import {
    MapService,
    LayerService,
    InteractionService,
    ConfigService
} from 'app/_services';
import {
    faVectorSquare,
    faAngleDown,
    faAngleUp,
    faCircle,
    faTimes,
    faSave
} from '@fortawesome/free-solid-svg-icons';
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';
import { Fill, Stroke, Style, Circle, Text } from 'ol/style';
import { Circle as CircleGeom } from 'ol/geom';
import Draw, { DrawEvent, GeometryFunction } from 'ol/interaction/Draw';
import { Snap, Modify } from 'ol/interaction';
import { altKeyOnly, shiftKeyOnly, singleClick } from 'ol/events/condition';
import { Coordinate, squaredDistance as squaredCoordinateDistance } from 'ol/coordinate';
import GeoJSON from 'ol/format/GeoJSON';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Type } from 'ol/geom/Geometry';
import BaseEvent from 'ol/events/Event';

@Component({
    selector: 'redline',
    templateUrl: 'redline.component.html',
    styleUrls: ['redline.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * With this tools you can make markings on the map
 * You can find these tools at the right of the map
 */
export class RedlineComponent implements OnInit, OnDestroy {
    // fontawsome icons
    readonly faVectorSquare = faVectorSquare;
    readonly faCircle = faCircle;
    readonly faTimes = faTimes;
    readonly farCircle = farCircle;

    readonly activeGeometryType = signal('');
    featureText: string;
    geometryFunction: GeometryFunction;

    constructor(
        public readonly interactionService: InteractionService,
        private readonly http: HttpClient,
        private readonly mapService: MapService,
        private readonly layerService: LayerService,
        private readonly bottomSheet: MatBottomSheet,
        private readonly configService: ConfigService
    ) {}

    ngOnDestroy(): void {
        this.bottomSheet.dismiss();
    }

    ngOnInit(): void {
        this.http
            .get(
                `${environment.api_base_url}/configuration/features/${
                    this.configService.config().id
                }`
            )
            .toPromise()
            .then((res: any) => {
                const features = new GeoJSON().readFeatures(res, {
                    // dataProjection: 'EPSG:4326',
                    featureProjection: this.mapService.projection
                });

                features.forEach(f => {
                    const style = new Style({
                        image: new Circle({
                            fill: new Fill({
                                color: f.get('fillColor') ?? 'rgb(29, 1, 139)'
                            }),
                            stroke: new Stroke({
                                color: f.get('lineColor') ?? 'rgb(29, 1, 139)',
                                width: f.get('lineWidth') ?? 4
                            }),
                            radius: 5
                        }),
                        fill: new Fill({
                            color:
                                f.get('fillColor') ?? 'rgba(247, 113, 73, 0.4)'
                        }),
                        stroke: new Stroke({
                            color: f.get('lineColor') ?? 'rgb(29, 1, 139)',
                            width: f.get('lineWidth') ?? 4
                        }),
                        text: new Text({
                            text: f.get('title') ?? '',
                            textAlign: 'center',
                            offsetY: 40,
                            scale: 1.4,
                            stroke: new Stroke({
                                color: '#ffffff',
                                width: 3
                            })
                        })
                    });

                    f.setStyle(style);
                });

                this.layerService
                    .redliningLayer()
                    .getSource()
                    .addFeatures(features);
            })
            .catch(err => {
                console.error(err);
            });
    }

    draw(type: Type): void {
        if (this.activeGeometryType() === type) {
            if (this.bottomSheet) this.bottomSheet.dismiss();
            this.interactionService.removeInteractions();
            this.setCursurType('default');
        } else {
            this.interactionService.removeInteractions();
            this.interactionService.draw.active =
                !this.interactionService.draw.active;
            this.setCursurType('crosshair');
        }

        if (
            !this.interactionService.getCoordinate.interaction &&
            this.interactionService.draw.active
        ) {
            this.openBottomsheet(this.bottomSheet, type);
            this.geometryFunction = undefined;

            if (type === 'Circle') {
                this.geometryFunction = (coordinates: Coordinate[], geometry: CircleGeom) => {
                    if (!geometry) {
                        geometry = new CircleGeom(coordinates[0]);
                    }
                    const center = coordinates[0];
                    const squaredLength = squaredCoordinateDistance(
                        center,
                        coordinates[coordinates.length - 1]
                    );

                    const radius = Math.sqrt(squaredLength);
                    this.interactionService.circle.radius = Math.round(radius);

                    geometry.setCenterAndRadius(center, radius);

                    return geometry;
                };
            }

            this.interactionService.draw.interaction = new Draw({
                source: this.layerService.redliningLayer().getSource(),
                type: type,
                freehandCondition: shiftKeyOnly,
                geometryFunction: this.geometryFunction
            });

            this.handleType(type);
        }

        this.activeGeometryType.set(type);

        this.setupInteractions();
    }

    private setCursurType(type: string): void {
        if (type) {
            document.getElementById('cook_map').style.cursor = type;
        }
    }

    private setupInteractions(): void {
        const map = this.mapService.map();

        this.interactionService.modifyDraw.interaction = new Modify({
            source: this.layerService.redliningLayer().getSource(),
            deleteCondition: event => altKeyOnly(event) && singleClick(event)
        });

        map.addInteraction(this.interactionService.modifyDraw.interaction);

        this.interactionService.modifyDraw.interaction.on('drawend', event => {
            this.saveRedlining();
        });

        this.interactionService.snap.interaction = new Snap({
            source: this.layerService.redliningLayer().getSource()
        });
        map.addInteraction(this.interactionService.snap.interaction);

        this.interactionService.draw.interaction.on(
            'change',
            (event: BaseEvent) => {
                console.log(event);
                event.target
                    .getOverlay()
                    .getSource()
                    .forEachFeature(feature => {
                        if (feature.getGeometry().flatCoordinates.length == 4) {
                            feature
                                .getGeometry()
                                .setRadius(
                                    this.interactionService.circle.radius
                                );
                        }
                    });
            }
        );

        map.addInteraction(this.interactionService.draw.interaction);
    }

    private handleType(type: string): void {
        switch (type) {
            case 'Circle':
                this.handleCircleDrawEnd();
                break;
            case 'Point':
                this.handlePointDrawEnd();
                break;
            case 'LineString':
                this.handleLineStringDrawEnd();
                break;
            case 'Polygon':
                this.handlePolygonDrawEnd();
                break;
            default:
                break;
        }
    }

    disableSelectFeatureInfo(): void {
        this.bottomSheet.dismiss();

        if (this.interactionService.deleteRedline.active) {
            this.interactionService.removeInteractions();
        } else {
            this.interactionService.removeInteractions();

            this.interactionService.deleteRedline.active = true;
            this.interactionService.deleteRedline.interaction = this.mapService
                .map()
                .on('singleclick', evt => {
                    this.mapService
                        .map()
                        .forEachFeatureAtPixel(evt.pixel, f => {
                            if (
                                this.layerService
                                    .redliningLayer()
                                    .getSource()
                                    .hasFeature(f)
                            ) {
                                this.layerService
                                    .redliningLayer()
                                    .getSource()
                                    .removeFeature(f);
                            }
                        });
                });
        }
    }

    openBottomsheet(bottomSheet, type): void {
        bottomSheet.open(BottomSheetComponent, {
            data: {
                type
            }
        });
    }

    saveRedlining() {
        if (!environment.public && false) {
            const features = this.layerService
                .redliningLayer()
                .getSource()
                .getFeatures();
            const geojson = new GeoJSON().writeFeaturesObject(features, {
                featureProjection: this.mapService.projection
            });
            const body = {
                id: this.configService.config().id,
                geojson
            };

            this.http
                .post(
                    `${environment.api_base_url}/configuration/features`,
                    body
                )
                .toPromise()
                .then((res: any) => {})
                .catch(err => {});
        }
    }

    private handleCircleDrawEnd(): void {
        this.interactionService.draw.interaction.on(
            'drawend',
            (event: DrawEvent) => {
                const feature = event.feature;

                const style = new Style({
                    image: new Circle({
                        fill: new Fill({
                            color: this.interactionService.circle.fillColor
                        }),
                        stroke: new Stroke({
                            color: this.interactionService.circle.lineColor
                        }),
                        radius: this.interactionService.circle.radius
                    }),
                    fill: new Fill({
                        color: this.interactionService.circle.fillColor
                    }),
                    stroke: new Stroke({
                        color: this.interactionService.circle.lineColor
                    }),
                    text: new Text({
                        text: this.interactionService.circle.title,
                        textAlign: 'center',
                        offsetY: -20,
                        scale: 1.4,
                        stroke: new Stroke({
                            color: '#fff',
                            width: 3
                        }),
                        fill: new Fill({
                            color: '#000'
                        })
                    })
                });
                // @shouldRemove
                // feature.set('text', this.interactionService.circle.title);
                feature.setStyle(style);
                feature.setProperties({
                    title: this.interactionService.circle.title,
                    radius: this.interactionService.circle.radius,
                    lineColor: this.interactionService.circle.lineColor,
                    fillColor: this.interactionService.circle.fillColor
                });
                this.saveRedlining();
            }
        );
    }

    private handlePolygonDrawEnd(): void {
        this.interactionService.draw.interaction.on(
            'drawend',
            (event: DrawEvent) => {
                const feature = event.feature;

                const style = new Style({
                    fill: new Fill({
                        color: this.interactionService.polygon.fillColor
                    }),
                    stroke: new Stroke({
                        color: this.interactionService.polygon.lineColor
                    }),
                    text: new Text({
                        text: this.interactionService.polygon.title,
                        textAlign: 'center',
                        offsetY: -20,
                        scale: 1.4,
                        stroke: new Stroke({
                            color: '#fff',
                            width: 3
                        }),
                        fill: new Fill({
                            color: '#000'
                        })
                    })
                });
                feature.setStyle(style);
                feature.setProperties({
                    title: this.interactionService.polygon.title,
                    lineColor: this.interactionService.polygon.lineColor,
                    fillColor: this.interactionService.polygon.fillColor
                });
                this.saveRedlining();
            }
        );
    }

    private handleLineStringDrawEnd(): void {
        this.interactionService.draw.interaction.on(
            'drawend',
            (event: DrawEvent) => {
                const feature = event.feature;

                const style = new Style({
                    stroke: new Stroke({
                        color: this.interactionService.line.lineColor,
                        width: this.interactionService.line.lineWidth
                    }),
                    text: new Text({
                        text: this.interactionService.line.title,
                        textAlign: 'center',
                        offsetY: -20,
                        scale: 1.4,
                        stroke: new Stroke({
                            color: '#fff',
                            width: 3
                        }),
                        fill: new Fill({
                            color: '#000'
                        })
                    })
                });
                feature.setStyle(style);
                feature.setProperties({
                    title: this.interactionService.line.title,
                    lineColor: this.interactionService.line.lineColor
                });
                this.saveRedlining();
            }
        );
    }

    private handlePointDrawEnd(): void {
        this.interactionService.draw.interaction.on(
            'drawend',
            (event: DrawEvent) => {
                const feature = event.feature;

                const style = new Style({
                    image: new Circle({
                        fill: new Fill({
                            color: this.interactionService.point.fillColor
                        }),
                        stroke: new Stroke({
                            color: this.interactionService.point.lineColor
                        }),
                        radius: 5
                    }),
                    fill: new Fill({
                        color: this.interactionService.point.fillColor
                    }),
                    stroke: new Stroke({
                        color: this.interactionService.point.lineColor
                    }),
                    text: new Text({
                        text: this.interactionService.point.title,
                        textAlign: 'center',
                        offsetY: -20,
                        scale: 1.4,
                        stroke: new Stroke({
                            color: '#fff',
                            width: 3
                        }),
                        fill: new Fill({
                            color: '#000'
                        })
                    })
                });
                feature.setStyle(style);
                feature.setProperties({
                    title: this.interactionService.point.title,
                    lineColor: this.interactionService.point.lineColor,
                    fillColor: this.interactionService.point.fillColor
                });
                this.saveRedlining();
            }
        );
    }
}

@Component({
    selector: 'bottom-edit',
    templateUrl: 'bottom-edit.html'
})
export class BottomSheetComponent {
    readonly faSave = faSave;
    readonly faAngleDown = faAngleDown;
    readonly faAngleUp = faAngleUp;

    readonly showBottemsheet = signal(true);

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA)
        public data: {
            type: string;
        },
        public readonly interactionService: InteractionService,
        public readonly mapService: MapService
    ) {}

    updateCircle(newRadius: number): void {
        this.interactionService.circle.newRadius = newRadius;
        this.interactionService.draw.interaction.changed();
    }

    closeBottomsheet(): void {
        this.showBottemsheet.set(!this.showBottemsheet());
    }
}
