import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'environments/environment';

export interface HistoryDialogData {
    id: string;
}

@Component({
    selector: 'history-dialog',
    templateUrl: 'history.dialog.html',
    styleUrl: 'history.dialog.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Dialog for the metadata
 */
export class HistoryDialogComponent implements OnInit {
    history = signal([]);
    constructor(
        public dialogRef: MatDialogRef<HistoryDialogComponent>,
        private http: HttpClient,
        private dateAdapter: DateAdapter<Date>,
        @Inject(MAT_DIALOG_DATA) public data: HistoryDialogData
    ) {
        this.dateAdapter.setLocale('nl');

    }

    ngOnInit() {
        this.http.get(environment.api_base_url + '/forms/history/' + this.data.id).subscribe((res: any) => {
            console.log(res)
            this.history.set(res.history);
            console.log(this.history)
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
