<div class="login-screen">
    <div class="container">
        <div class="card card-container">
            <img
                id="profile-img"
                class="profile-img-card mt-2"
                src="{{ env.subDirectory }}/assets/img/logo_{{
                    env.loginPrefix
                }}.png"
            />
            <p id="profile-name" class="profile-name-card">{{ error() }}</p>
            <form class="form-group" name="loginForm" (ngSubmit)="login()">
                <span id="reauth-email" class="reauth-email"></span>
                <input
                    type="text"
                    id="inputUsername"
                    autocomplete="username"
                    class="form-control"
                    placeholder="Gebruikersnaam of Email"
                    required
                    autofocus
                    name="username"
                    [(ngModel)]="username"
                />

                <div class="input-group">
                    <input
                        type="password"
                        id="inputPassword"
                        autocomplete="current-password"
                        class="form-control"
                        placeholder="Wachtwoord"
                        name="password"
                        [type]="hide() ? 'password' : 'text'"
                        [(ngModel)]="password"
                    />
                    <div class="input-group-append" (click)="hide.set(!hide())">
                        <div class="input-group-text">
                            <mat-icon>{{
                                hide() ? 'visibility_off' : 'visibility'
                            }}</mat-icon>
                        </div>
                    </div>
                </div>
                <button
                    class="btn btn-lg btn-primary btn-block btn-signin"
                    type="submit"
                    [disabled]="loading()"
                >
                    Inloggen
                </button>
                <button
                    class="btn btn-lg btn-info btn-block btn-signin"
                    *ngIf="env.active_directory"
                    (click)="ADLogin()"
                    type="button"
                >
                    Azure SSO
                </button>
            </form>
            <br />
            <a routerLink="/password/reset" class="forgot-password"
                >Wachtwoord vergeten?</a
            >
            <a href="https://www.gisarts.nl/" target="_blank"
                ><img
                    src="{{ env.subDirectory }}/assets/img/logo_gisarts.png"
                    class="logo_gisarts"
            /></a>
        </div>
    </div>
    <div
        id="image"
        *ngIf="configService.backgroundImage"
        [style.backgroundImage]="'url(' + configService.backgroundImage + ')'"
    ></div>
    <video
        muted
        autoplay
        loop
        id="myVideo"
        class="fade-in"
        *ngIf="configService.backgroundVideo"
    >
        <source [src]="configService.backgroundVideo" type="video/mp4" />
    </video>
</div>
