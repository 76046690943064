import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/_services';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard {
    constructor(public auth: AuthService, public router: Router) {}
    /**
     * Check if the user has the permission to navigate to the route
     * @return {boolean} [description]
     */
    canActivate(): boolean {
        if (this.auth.id && this.auth.hasPermission(['manager', 'admin'])) {
            return true;
        }
        this.router.navigate(['login']);

        return false;
    }
}
