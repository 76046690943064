<main>
    <ngx-loading [show]="configService.configLoading()"></ngx-loading>
    <mat-spinner *ngIf="loading()" [diameter]="50"></mat-spinner>

    <div *ngIf="configService.config()" class="menu-container">
        <button
            type="button"
            class="btn"
            (click)="
                sidenavService.setWidth(
                    sidenavService.widthValue() ? 0 : 380,
                    'px'
                )
            "
        >
            <fa-icon [icon]="faBars"></fa-icon>
        </button>
        <search
            [searchEntry]="activatedRoute.snapshot.paramMap.get('element')"
            *ngIf="
                configService.config()?.tools?.search &&
                configService.config()?.tools?.search?.enabled
            "
        ></search>
        <account *ngIf="authService.id() && !environment.public"></account>

        <!-- Usermanual -->
        <button
            mat-mini-fab
            class="manualButton"
            color="secondary"
            *ngIf="
                configService.config().options?.usermanual && !authService.id()
            "
            (click)="openPdf()"
            matToolTip="Handleiding"
        >
            <fa-icon [icon]="faBookOpen"></fa-icon>
        </button>
    </div>

    <!-- Routing -->
    <cook-routing *ngIf="configService.config()?.tools?.routing"></cook-routing>

    <mat-drawer-container style="height: 100dvh" hasBackdrop="false" autosize>
        <mat-drawer
            mode="side"
            [opened]="sidenavService.isOpen()"
            [style.width]="sidenavService.width()"
            (onOpenStart)="sidenavService.setWidth(380, 'px')"
            (onCloseStart)="sidenavService.setWidth(0, 'px')"
        >
            <mat-tab-group
                mat-stretch-tabs="false"
                mat-align-tabs="start"
                class="mainTabNavigation"
                *ngIf="configService.config()"
                dynamicHeight
                (selectedTabChange)="onTabChanged($event)"
                [selectedIndex]="sidenavService.tabIndex()"
            >
                <!-- Create a hidden tab to switch to when the menu is closed -->
                <mat-tab *ngIf="!sidenavService.isOpen()">
                    <ng-template matTabContent></ng-template>
                </mat-tab>

                <!-- Legend -->
                <mat-tab
                    class="mt-1"
                    *ngIf="
                        configService.config().maps &&
                        configService.config().options &&
                        configService.config().options.legend
                    "
                >
                    <ng-template mat-tab-label>
                        <mat-icon matTooltip="Legenda">layers</mat-icon>
                    </ng-template>
                    <ng-template matTabContent>
                        <legend cdkscrollable class="mt-2"></legend>
                    </ng-template>
                </mat-tab>

                <!-- Detail -->
                <mat-tab
                    *ngIf="
                        configService.config() &&
                        detailService.features() !== undefined
                    "
                >
                    <ng-template mat-tab-label>
                        <mat-icon matTooltip="Informatie">info</mat-icon>
                    </ng-template>
                    <ng-template matTabContent>
                        <detail></detail>
                    </ng-template>
                </mat-tab>

                <!-- Forms -->
                <ng-container
                    *ngIf="
                        configService.config()?.forms?.length &&
                        !environment.public
                    "
                >
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon matTooltip="Formulieren"
                                >edit_note</mat-icon
                            >
                        </ng-template>
                        <ng-template matTabContent>
                            <detailform></detailform>
                        </ng-template>
                    </mat-tab>

                    <mat-tab
                        *ngIf="environment.loginPrefix !== 'hta_' && false"
                    >
                        <ng-template mat-tab-label>
                            <mat-icon matTooltip="Gemeld"
                                >format_list_bulleted</mat-icon
                            >
                        </ng-template>
                        <ng-template matTabContent>
                            <form-overview></form-overview>
                        </ng-template>
                    </mat-tab>
                </ng-container>

                <!-- Filters -->
                <mat-tab *ngIf="configService.config().tools?.filters">
                    <ng-template mat-tab-label>
                        <mat-icon matTooltip="Filters"
                            ><fa-icon [icon]="faFilter"></fa-icon
                        ></mat-icon>
                    </ng-template>
                    <ng-template matTabContent>
                        <filter></filter>
                    </ng-template>
                </mat-tab>

                <!-- Print -->
                <mat-tab
                    *ngIf="
                        configService.config()?.tools?.print?.configs?.length
                    "
                >
                    <ng-template mat-tab-label>
                        <mat-icon matTooltip="Print">print</mat-icon>
                    </ng-template>
                    <ng-template matTabContent>
                        <print-form></print-form>
                    </ng-template>
                </mat-tab>

                <!-- Saved Locations -->
                <mat-tab *ngIf="configService.config().options?.customExtent">
                    <ng-template mat-tab-label>
                        <mat-icon matTooltip="Opgeslagen Locaties"
                            >place</mat-icon
                        >
                    </ng-template>
                    <ng-template matTabContent>
                        <custom-extent></custom-extent>
                    </ng-template>
                </mat-tab>

                <ng-container
                    *ngIf="configService.config().tools?.notifications"
                >
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon matTooltip="Melden">add_alert</mat-icon>
                        </ng-template>
                        <ng-template matTabContent>
                            <message></message>
                        </ng-template>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon matTooltip="Gemeld"
                                >notifications</mat-icon
                            >
                        </ng-template>
                        <ng-template matTabContent>
                            <messages></messages>
                        </ng-template>
                    </mat-tab>
                </ng-container>

                <!-- Download -->
                <mat-tab *ngIf="configService.config().tools?.downloads">
                    <ng-template mat-tab-label>
                        <mat-icon matTooltip="Downloads">save_alt</mat-icon>
                    </ng-template>
                    <ng-template matTabContent>
                        <cook-download></cook-download>
                    </ng-template>
                </mat-tab>

                <!-- Query -->
                <mat-tab *ngIf="configService.config().tools?.query">
                    <ng-template mat-tab-label>
                        <mat-icon matTooltip="Query builder">build</mat-icon>
                    </ng-template>
                    <ng-template matTabContent>
                        <query></query>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </mat-drawer>

        <div class="mat-drawer-content">
            <div class="selectie-tools">
                <!-- Navigation & Location -->
                <div
                    class="navigationTools tools"
                    *ngIf="configService.config()"
                >
                    <navigation></navigation>
                    <!-- Fallback because it is not in everyones config -->
                    <geolocation
                        *ngIf="
                            configService.config().options?.geolocation !==
                            false
                        "
                    ></geolocation>
                </div>

                <!-- Delen -->
                <div
                    class="tools"
                    *ngIf="configService.config()?.options?.share"
                >
                    <share></share>
                </div>

                <!-- info -->
                <div
                    class="infoTools tools"
                    *ngIf="
                        configService.config()?.tools?.info?.length ||
                        infoClick()
                    "
                >
                    <info></info>
                    <buffer></buffer>
                    <poly-info></poly-info>
                    <circle-info></circle-info>
                </div>

                <!-- Obliques -->
                <div class="obliqueTools tools">
                    <cyclo
                        *ngIf="
                            configService.config()?.tools?.cyclo ||
                            configService.config()?.options?.cyclo
                        "
                    ></cyclo>
                    <oblique
                        *ngIf="configService.config()?.tools?.oblique"
                    ></oblique>
                    <cook-vr-photo
                        *ngIf="configService.config()?.tools?.vrPhoto"
                    ></cook-vr-photo>
                </div>

                <!-- Measure -->
                <div
                    class="measureTools tools"
                    *ngIf="configService.config()?.options?.measure"
                >
                    <measure></measure>
                </div>

                <!-- Get Coordinates -->
                <div class="coordinateTools tools">
                    <!-- these lines need to be added when the configService.config() files are updated! -->
                    <get-coordinate
                        *ngIf="configService.config()?.options?.getCoordinate"
                    ></get-coordinate>
                    <zoom-to-coordinate
                        *ngIf="
                            configService.config()?.options?.zoomToCoordinate
                        "
                    ></zoom-to-coordinate>
                </div>

                <!-- Custom Extent -->
                <div class="customExtentTools tools">
                    <create-custom-extent
                        *ngIf="
                            configService.config()?.options?.customExtent &&
                            !environment.public
                        "
                    ></create-custom-extent>
                </div>

                <!-- Redlining -->
                <div class="redliningTools tools">
                    <redline
                        *ngIf="configService.config()?.options?.redline"
                    ></redline>
                </div>

                <div class="threejsTools tools">
                    <button
                        *ngIf="configService.config()?.options?.threejs"
                        mat-mini-fab
                        color="secondary"
                        matTooltip="Schakel naar 3D omgeving"
                        type="button"
                        class="tool"
                        [routerLink]="['three-dimensional']"
                    >
                        <fa-icon [icon]="faGlobe"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="create-tools">
                <edit
                    *ngIf="
                        interactionService?.editLayers?.length ||
                        (configService.config()?.forms?.length &&
                            !environment.public)
                    "
                ></edit>
            </div>

            <background *ngIf="configService.config()"></background>

            <div id="cook_map"></div>
            <!-- Attribution is required for copyright -->
            <div id="attribution"></div>
            <!-- @shouldRemove fix grid so it is not possible to scroll when nothing is selected
                 NOTE: in the grid.component.html I found a possible fix not tested yet
            -->
            <grid *ngIf="configService.config()"></grid>
        </div>
    </mat-drawer-container>
</main>
