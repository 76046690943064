import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ConfigService } from 'app/_services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { DeleteDialogComponent } from 'app/_dialogs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'setting-custom-legend',
    templateUrl: './setting-custom-legend.component.html',
    styleUrls: ['./setting-custom-legend.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingCustomLegendComponent {
    private readonly environment = environment;

    constructor(
        private readonly configService: ConfigService,
        private readonly http: HttpClient,
        private readonly dialog: MatDialog,
        private readonly snackBar: MatSnackBar
    ) {}

    onDelete(): void {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            data: {
                type: 'legenda',
                descriptor: 'uw persoonlijke legenda'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.delete) {
                this.delete();
            }
        });
    }

    delete(): void {
        const headers = new HttpHeaders().set(
            'Content-Type',
            'application/json; charset=utf-8'
        );

        this.http
            .delete(
                this.environment.api_base_url +
                    '/legend/' +
                    this.configService.config().id,
                { headers: headers, observe: 'response', responseType: 'json' }
            )
            .toPromise()
            .then(res => {
                this.snackBar.open(
                    'Uw persoonlijke legenda instellingen zijn verwijderd',
                    'Ok',
                    {
                        panelClass: 'white-snackbar',
                        verticalPosition: 'bottom',
                        duration: 3000
                    }
                );
            })
            .catch(err => {
                console.error(err);
            });
    }
}
