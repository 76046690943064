<button
    mat-mini-fab
    color="secondary"
    class="tool"
    (click)="selectFeatureInfo()"
    matTooltip="{{ message }}"
    matTooltipPosition="left"
    [class.active]="interactionService.info.active"
>
    <fa-icon [icon]="faMousePointer"></fa-icon>
</button>
