import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EffectRef,
    OnDestroy,
    OnInit,
    effect,
    signal
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import {
    EditService,
    LayerService,
    MapService,
    DetailService,
    InteractionService,
    SidenavService,
    ConfigService,
    DrawService
} from 'app/_services';
import { DateAdapter } from '@angular/material/core';
import { Observable } from 'rxjs';
// import { Image } from '@ks89/angular-modal-gallery';
import { environment } from 'environments/environment';
import {
    faTimes,
    faDownload,
    faCopy,
    faDrawPolygon
} from '@fortawesome/free-solid-svg-icons';
import WFS from 'ol/format/WFS';
import GML3 from 'ol/format/GML3';
import { Feature } from 'ol';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import GeoJSON from 'ol/format/GeoJSON';
import { Draw, Modify, Translate } from 'ol/interaction';
import { FormService } from 'app/_services/form.service';
import { HttpEventType } from '@angular/common/http';

@Component({
    selector: 'detailform',
    templateUrl: 'detailform.component.html',
    styleUrls: ['detailform.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * @shouldRemove
 */
export class DetailFormComponent implements OnInit, OnDestroy {
    readonly faTimes = faTimes;
    readonly faDownload = faDownload;
    readonly faCopy = faCopy;
    readonly faDrawPolygon = faDrawPolygon;
    readonly environment = environment;
    readonly feature = signal({});
    readonly featureType = signal(undefined);

    // Type: Defines the part of the html to load
    readonly featureTypeSubscription: EffectRef;
    readonly configSubscription: EffectRef;

    geomType: any;

    // File upload
    // dragOver: boolean;
    // uploadedFiles = [];
    // images: Image[] = [];

    readonly loading = signal(false);
    uploadProgresses: { [key: string]: any } = {};

    readonly forms = signal(undefined);
    form: FormGroup;

    uploadedFiles = [];

    // In case of a progress form we use steps
    step = 0;

    constructor(
        private readonly mapService: MapService,
        private readonly detailService: DetailService,
        private readonly http: HttpClient,
        private readonly sidenavService: SidenavService,
        private readonly dateAdapter: DateAdapter<Date>,
        private readonly drawService: DrawService,
        private readonly formService: FormService,
        private readonly cdr: ChangeDetectorRef,
        readonly layerService: LayerService,
        readonly configService: ConfigService,
        readonly sanitizer: DomSanitizer,
        readonly editService: EditService,
        readonly interactionService: InteractionService
    ) {
        this.dateAdapter.setLocale('nl');

        this.configSubscription = effect(
            () => {
                if (this.configService.config()) {
                    // @Todo add support for multiple forms
                    this.forms.set(this.configService.config().forms[0]);

                    if (this.forms) {
                        this.createFormControls();
                    }

                    if (this.forms().type === 'intern') {
                    }
                }
            },
            { allowSignalWrites: true }
        );

        this.featureTypeSubscription = effect(
            () => {
                const featureType = this.editService.featureType();
                const feature = this.editService.feature();

                if (feature) {
                    console.log(feature)
                    if (featureType) this.featureType.set(featureType);

                    if (this.forms().type === 'intern') {
                        this.http.get(environment.api_base_url + '/forms/register/' + feature.getProperties().id).subscribe({
                            next: response => {
                                const register: any = response;

                                const values = {};
                                register.values.forEach(value => {
                                    values[value.item_id] = value.value;
                                });
                                console.log(register)
                                this.feature.set(values)
                                this.form.patchValue(this.feature());

                                // Add one more for the edit to progress to the next step
                                this.step = register.step + 1;

                                console.log(this.feature(), this.step)

                                this.form = undefined;
                                this.createFormControls()
                            },
                            error: error => {
                                console.log(error);
                            }
                        });

                    } else {
                        this.feature.set(feature.getProperties());
                        this.form.patchValue(this.feature());
                    }

                } else {
                    this.feature.set({});
                }
            },
            { allowSignalWrites: true }
        );

        this.sidenavService.setWidth(600, 'px');
    }

    ngOnInit(): void {
        this.detailService.setFeatures(undefined);
    }

    ngOnDestroy(): void {
        if (this.interactionService.duplicate.interaction) {
            this.mapService
                .map()
                .un(
                    this.interactionService.duplicate.interaction,
                    this.interactionService.duplicate.interaction
                );
        }
        // .un(type.interaction.type, type.interaction.listener);

        // this.editService.layer().getSource().refresh();
        // this.layerService.drawingLayer().getSource().clear();
    }

    createFormControls() {
        const group: any = {};

        this.forms().form_items.forEach(item => {
            const value: string = item.value ? item.value : item.id;
            if (item.type === 'checkbox') {
                group[value] = new FormControl(false);
            } else if (item.type === 'select') {
                group[value] = new FormControl('');
            } else {
                group[value] = new FormControl('');
            }
        });

        this.form = new FormGroup(group);
    }

    delete(): void {
        if (this.forms().type === 'intern') {
            const url =
                environment.api_base_url +
                '/forms/values/' +
                this.editService.feature().get('id');

            this.http.delete(url, { responseType: 'json' }).subscribe({
                next: response => {
                    this.loading.set(false);
                    // this.editService.layer().getSource().clear();
                    // this.editService.layer().getSource().refresh();
                    // this.layerService.drawingLayer().getSource().clear();

                    this.formService.formLayer.getSource().refresh();
                    this.interactionService.removeInteractions();

                    this.feature.set({});
                    this.sidenavService.tabIndex.set(0);
                    this.sidenavService.setWidth(0, 'px');
                },
                error: error => {
                    console.log(error);
                }
            });
        } else {
            if (
                confirm(
                    'Weet je zeker dat je het geselecteerde object wilt verwijderen? ' +
                        name
                )
            ) {
                this.processRequest(true);
            }
        }
    }

    save(): void {
        if (this.forms().type === 'geoserver') {
            this.processRequest();
        } else {
            this.process();
        }
    }

    process() {
        this.loading.set(true);
        let formData = this.form.value;

        let geojson;
        if (this.editService.feature()) {
            geojson = new GeoJSON().writeFeatureObject(
                this.editService.feature()
            );
        } else {
            geojson = new GeoJSON().writeFeatureObject(
                this.layerService.drawingLayer().getSource().getFeatures()[0]
            );
        }

        // Make sure to check the data for optional notifies

        if (
            this.editService.feature() &&
            this.editService.feature().get('id')
        ) {
            const url = environment.api_base_url + '/forms/values';

            this.http
                .put(
                    url,
                    {
                        id: this.editService.feature().get('id'),
                        value: formData,
                        form_id: this.forms().id,
                        geom: geojson
                    },
                    { responseType: 'json' }
                )
                .subscribe({
                    next: response => {
                        this.loading.set(false);
                        // this.editService.layer().getSource().clear();
                        // this.editService.layer().getSource().refresh();
                        // this.layerService.drawingLayer().getSource().clear();

                        this.formService.formLayer.getSource().refresh();
                        this.interactionService.removeInteractions();

                        this.layerService.drawingLayer().getSource().clear();

                        this.feature.set({});
                        this.sidenavService.tabIndex.set(0);
                    },
                    error: error => {
                        console.log(error);
                    }
                });
        } else {
            const url = environment.api_base_url + '/forms/values';

            this.http
                .post(
                    url,
                    {
                        value: formData,
                        form_id: this.forms().id,
                        geom: geojson
                    },
                    { responseType: 'json' }
                )
                .subscribe({
                    next: response => {
                        this.loading.set(false);
                        // this.editService.layer().getSource().clear();
                        // this.editService.layer().getSource().refresh();
                        // this.layerService.drawingLayer().getSource().clear();

                        this.formService.formLayer.getSource().refresh();
                        this.interactionService.removeInteractions();
                        this.layerService.drawingLayer().getSource().clear();

                        this.feature.set({});
                        this.sidenavService.tabIndex.set(0);
                    },
                    error: error => {
                        console.log(error);
                    }
                });
        }
    }

    /**
     * Create a insert, update or delete request to the server and send it to the server
     */
    processRequest(delete_request = false): void {
        this.loading.set(true);

        const featureType = this.editService.featureType() ?? this.interactionService.editLayers[0].get('featureType');
        const formatWFS = new WFS();
        const formatGML3: any = new GML3({
            featureNS: this.editService.namespace() ?? 'private',
            featureType,
            srsName: 'EPSG:28992'
        });

        // Fix the dates for server side parsing
        const requestProperties = this.form.value;
        const keys = Object.keys(requestProperties);

        keys.forEach(key => {
            if (requestProperties[key] instanceof Date) {
                requestProperties[key] = moment(
                    requestProperties[key],
                    'YYYY-MM-DD'
                ).toISOString();
            }
        });

        let requestFeature: any;
        let geometry: any;
        // Update the features properties
        if (this.editService.feature()) {
            requestFeature = this.editService.feature();
            geometry = this.editService.feature().getGeometry();
        } else {
            const layer = this.interactionService.editLayers[0];

            requestFeature = layer.getSource().getFeatures()[0];
            geometry = this.layerService.drawingLayer().getSource().getFeatures()[0].getGeometry();
        }
        requestFeature.setProperties(requestProperties);
        this.editService.feature.set(requestFeature);

        // Geometry name should be dynamic set
        requestFeature.setGeometryName('geometry');
        requestFeature.setGeometry(geometry);

        // For now, only insert and update data (writeTransaction(inserts, updates, deletes, options))
        let transaction;
        // If there is an id, update it, delete it or insert a new feature
        // If there is a specific action, execute that action
        if (delete_request) {
            transaction = formatWFS.writeTransaction(
                null,
                null,
                [requestFeature],
                formatGML3
            );
        } else if (requestFeature.id_) {
            transaction = formatWFS.writeTransaction(
                null,
                [requestFeature],
                null,
                formatGML3
            );
        } else {
            transaction = formatWFS.writeTransaction(
                [requestFeature],
                null,
                null,
                formatGML3
            );
        }

        const xs = new XMLSerializer();
        const data = xs.serializeToString(transaction);

        this.sendRequest(data).subscribe(response => {
            // this.editService.layer().getSource().clear();
            this.editService.layer().getSource().refresh();
            this.layerService.drawingLayer().getSource().clear();
            this.interactionService.editLayers[0].getSource().refresh();

            this.feature.set({});
            this.sidenavService.tabIndex.set(0);
            this.sidenavService.setWidth(0, 'px');

            this.loading.set(false);
        });
    }

    sendRequest(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'text/xml');

        const layer = this.interactionService.editLayers[0];

        if (
            layer &&
            layer.get('authorization')
        ) {
            headers = headers.set(
                'Authorization',
                layer.get('authorization')
            );
        }

        return this.http.post(
            layer.getSource().getUrl(),
            data,
            { headers: headers, responseType: 'text' }
        );
    }

    /**
     * Cancel the current edit and deselect the tool, clear the feature layer
     */
    cancel(): void {
        this.interactionService.removeInteractions();
        this.layerService.drawingLayer().getSource().clear();
        this.feature.set({});

        this.sidenavService.tabIndex.set(0);
    }

    onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    onDrop(event: DragEvent, prop): void {
        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer?.files;
        if (files && files.length) {
            this.handleFile(files[0], prop);
        }
    }

    uploadListener($event: any, prop): void {
        const input = $event.target as HTMLInputElement;
        if (input.files && input.files[0]) {
            this.handleFile(input.files[0], prop);
        }
    }

    handleFile(file: File, prop): void {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            this.uploadProgresses[prop] = 0;

            const url = environment.api_base_url + '/upload';

            this.http
                .post(url, formData, {
                    reportProgress: true,
                    observe: 'events'
                })
                .subscribe({
                    next: event => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                if (event.total) {
                                    const progress = Math.round(
                                        (100 * event.loaded) / event.total
                                    );
                                    this.uploadProgresses[prop] = progress;
                                }
                                break;
                            case HttpEventType.Response:
                                if (event.body) {
                                    const body: any = event.body;
                                    this.uploadedFiles.push(body);
                                    // Do not remove ''
                                    this.form.get('' + prop).setValue(body.id);

                                    // Zorg ervoor dat de voortgang wordt bijgewerkt naar 100% als de upload voltooid is
                                    this.uploadProgresses[prop] = 100;

                                    console.log(this.form.value);
                                }
                                break;
                            default:
                                // Behandel andere event types indien nodig
                                break;
                        }
                    },
                    error: error => {
                        console.log('ERROR', error);
                        // Voeg hier error handling toe
                        // Reset de voortgang in geval van een fout
                        this.uploadProgresses[prop] = 0;
                    }
                });
        }
    }

    deleteFile(value): void {
        const id = this.feature()[value];

        this.form.get(value).patchValue(undefined);
        this.feature()[value] = undefined;

        const url = environment.api_base_url + '/upload/' + id;

        this.http.delete(url).subscribe((res: any) => {
            console.log(res);
        });
    }

    createFeature(layer): void {
        this.interactionService.removeInteractions();
        this.interactionService.create.active = true;

        if (this.editService.layer()) {
            this.editService.layer().getSource().refresh();
        }
        this.editService.setfeatureType(undefined);

        if (this.interactionService.create.interaction) {
            this.mapService
                .map()
                .removeInteraction(this.interactionService.create.interaction);
        }

        if (this.interactionService.duplicate.interaction) {
            this.mapService
                .map()
                .removeInteraction(
                    this.interactionService.duplicate.interaction
                );
        }

        this.editService.feature.set(undefined);
        
        this.interactionService.translate.interaction = new Translate({
            layers: [this.layerService.drawingLayer()]
        });

        this.mapService.map().addInteraction(this.interactionService.translate.interaction);

        const modify = new Modify({
            source: this.layerService
            .drawingLayer().getSource()
        });

        this.mapService.map().addInteraction(modify);

        if (this.geomType === 'Copy') {
            this.copyFeature();
        } else {
            this.drawFeature(layer);
    }
    }

    drawFeature(layer): void {
        // Add snap and edit to drawlayer
        this.editService.layer.set(layer);
        this.editService.feature.set(undefined);

        this.interactionService.create.interaction = new Draw({
            source: layer.getSource(),
            type: this.geomType,
            style: this.drawService.styles['yellow']
        });
        this.mapService
            .map()
            .addInteraction(this.interactionService.create.interaction);

        this.interactionService.create.interaction.on('drawend', evt => {
            // layer.get('source').refresh();
            
            this.layerService
            .drawingLayer()
            .getSource()
            .clear();

            if (evt.feature) {
                this.layerService
                    .drawingLayer()
                    .getSource()
                    .addFeature(evt.feature.clone());
            }

            this.cdr.detectChanges();
        });
    }

    copyFeature(): void {
        this.interactionService.duplicate.interaction = this.mapService
            .map()
            .on('click', evt => {
                this.layerService.drawingLayer().getSource().clear();
                const f = this.mapService
                    .map()
                    .getFeaturesAtPixel(evt.pixel)[0];

                if (f) {
                    const feature = f as Feature; // Explicitly cast to ol.Feature

                    this.layerService
                        .drawingLayer()
                        .getSource()
                        .clear();

                    this.layerService
                        .drawingLayer()
                        .getSource()
                        .addFeature(feature.clone());
                }
            });
    }
}
