<button
    mat-mini-fab
    color="secondary"
    type="button"
    class="tool"
    (click)="drawFeatureInfo()"
    matTooltip="Informatie Oppervlak"
    matTooltipPosition="left"
    [class.active]="interactionService.polyinfo.active"
>
    <fa-icon [icon]="faVectorSquare"></fa-icon>
</button>
