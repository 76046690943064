<mat-card class="card p-3">
    <h4>Legenda</h4>
    <div>
        De legenda van deze configuratie herstellen naar de standaard
        instellingen?
        <button mat-icon-button (click)="onDelete()">
            <mat-icon color="warn">delete</mat-icon>
        </button>
    </div>
</mat-card>
