<h1 mat-dialog-title>Geschiedenis Bekijken</h1>

<div *ngFor="let item of history()">
    <mat-divider></mat-divider>
    <p class="history-entry p-4">
        {{ item.user.first_name }} laatst aangepast op
        <span class="date">{{
            item.created_at | date : 'dd-MM-yyyy HH:mm'
        }}</span>
    </p>
</div>
