<button
    mat-mini-fab
    color="secondary"
    type="button"
    class="tool"
    (click)="toggleGps()"
    matTooltip="GPS"
    matTooltipPosition="left"
    [ngClass]="{ active: geolocationEnabled }"
>
    <fa-icon [icon]="faCrosshairs"></fa-icon>
</button>
