import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService, ConfigService, MapService } from 'app/_services';
import { environment } from 'environments/environment';
import { faUser, faMapSigns, faSync } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { SettingsDialogComponent } from '../setting/setting.component';
import { UserManualDialogComponent } from '../_dialogs/user-manual/user-manual.dialog';
import { UserPasswordResetDialogComponent } from '../_dialogs/user-password-reset/user-password-reset.dialog';
import { CreateNotificationDialogComponent } from '../_dialogs/create-notification/create-notification.dialog';
import { ApprovingDialogComponent } from '../_dialogs/approving/approving.dialog';
import { MultiFactorAuthDialogComponent } from '../_dialogs/multi-factor-auth/multi-factor-auth.dialog';
import { DashboardDialogComponent } from 'app/_dialogs';
import { HttpClient } from '@angular/common/http';
import * as localforage from 'localforage';
import { Router } from '@angular/router';

@Component({
    selector: 'account',
    templateUrl: 'account.component.html',
    styleUrls: ['account.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * This component handles the menu in the left top corner
 */
export class AccountComponent {
    readonly environment = environment;
    readonly faUser = faUser;
    readonly faMapSign = faMapSigns;
    readonly faSync = faSync;

    private readonly dialogWidth = (2 / 3) * 100 + '%';

    constructor(
        readonly authService: AuthService,
        readonly configService: ConfigService,
        private readonly dialog: MatDialog,
        private readonly mapService: MapService,
        private readonly http: HttpClient,
        private readonly router: Router
    ) {
        this.loadConfigurations();
    }

    loadConfiguration(configuration): void {
        this.router.navigate(['/']).then(() => {
            this.configService.configLoading.set(true);
            this.configService.config.set(undefined);
            this.mapService.map.set(undefined);
            this.configService.loadConfiguration(configuration.id);
            // Set the title of our configuration
            this.configService.setTitle();
        });
    }

    loadDashboard(dashboard): void {
        this.dialog.open(DashboardDialogComponent, {
            width: '65vw',
            height: '75vh',
            data: dashboard
        });
    }

    openPdf(): void {
        this.dialog.open(UserManualDialogComponent, {
            width: '65vw',
            height: '75vh'
        });
    }

    openSettings(): void {
        this.dialog.open(SettingsDialogComponent, {
            width: '800px',
            height: '500px'
        });
    }

    openChangePassword(): void {
        this.dialog.open(UserPasswordResetDialogComponent, {
            width: '40vw',
            height: '40vh'
        });
    }

    openNotifications(): void {
        this.dialog.open(CreateNotificationDialogComponent, {
            width: '55vw',
            height: '65vh'
        });
    }

    open2FA(): void {
        this.dialog.open(MultiFactorAuthDialogComponent, {
            width: this.dialogWidth
        });
    }

    openApprovements(): void {
        this.dialog.open(ApprovingDialogComponent, {
            width: '55vw',
            height: '65vh'
        });
    }

    checkApprovement(receivers): void {
        return receivers.find(id => id === this.authService.id());
    }

    logout(): void {
        this.configService.setTitle();
        this.authService.logout();
    }

    openLink(): void {
        window.open('https://www.gisarts.nl/privacyverklaring/', '_blank');
    }

    loadConfigurations(): void {
        const url = `${this.environment.api_base_url}/configs`;

        this.http.get(url).subscribe({
            next: (res: any) => {
                this.authService.configurations.set(res);
                localforage.setItem('configurations', res);
            },
            error: (error: any) => {
                console.error('Error fetching data:', error);
            }
        });
    }
}
