import {
    Component,
    OnInit,
    OnDestroy,
    EffectRef,
    effect,
    signal,
    ViewChild,
    AfterViewInit
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MapService, ConfigService, LayerService, SidenavService, ExcelService, EditService } from 'app/_services';
import { DateAdapter } from '@angular/material/core';
import { environment } from 'environments/environment';
import { faFilePdf, faMapPin } from '@fortawesome/free-solid-svg-icons';
import * as FileSaver from 'file-saver';
import { FormService } from 'app/_services/form.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HistoryDialogComponent } from '../history/history.dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'form-overview',
    templateUrl: 'form-overview.component.html',
    styleUrls: ['form-overview.component.scss'],
    animations: [
        trigger('expand', [
          state('collapsed,void', style({height: '0px', minHeight: '0'})),
          state('expanded', style({height: '*'})),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
      ],
})
/**
 * @shouldRemove add description
 */
export class FormOverviewComponent implements OnInit, OnDestroy, AfterViewInit {
    // fontawsome icons
    faFilePdf = faFilePdf;
    faMapPin = faMapPin;
    displayedColumns: string[] = ['created_by', 'updated_at'];
    displayedColumnsWithExpand: string[] = [...this.displayedColumns, 'expand'];

    form: any;
    env: any;
    readonly total = signal(0);
    readonly pageSizeOptions = signal([]);
    dataSource: MatTableDataSource<any>;
    expandedElement

    filterOptions = [];
    selectedFilter

    // Feature: the features to load
    features: any[];
    layer: any;
    properties: any;
    config: any;
    values: any;
    configSubscription: EffectRef;

    form_value_id: any;

    readonly searchInput = signal('');

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    // Type: Defines the part of the html to load
    featureType: string;
    filter: string;

    constructor(
        private mapService: MapService,
        private formService: FormService,
        private readonly excelService: ExcelService,
        private sidenavService: SidenavService,
        private editService: EditService,
        readonly activatedRoute: ActivatedRoute,
        private layerService: LayerService,
        private configService: ConfigService,
        private http: HttpClient,
        private dateAdapter: DateAdapter<Date>,
        public sanitizer: DomSanitizer,
        protected readonly dialog: MatDialog,
    ) {
        this.dateAdapter.setLocale('nl');
        this.featureType = '';
        this.env = environment;



        this.configSubscription = effect(
            () => {
                if (this.configService.config()) {
                    // @Todo add support for multiple forms
                    this.form = this.configService.config().forms[0];
                    console.log(this.form)
                    this.http
                        .get(
                            environment.api_base_url +
                                '/forms/list_values/' +
                                this.form.id
                        )
                        .subscribe((res: any) => {
                            console.log(res)
                            this.dataSource = res.data;
                            this.total.set(res.total);

                        });

                    if (this.form.form_items) {
                        
                        this.form.form_items.forEach(item => {
                            if (item.id === 82) {
                                this.filterOptions = item.selects;
                                this.dataSource.filterPredicate = (data, filter: string) => {
                                    return data[item.name].toLowerCase().includes(filter);
                                };
                            }
                        });
                    }
                }
            },
            { allowSignalWrites: true }
        );

        this.sidenavService.setWidth(1000, 'px');

        this.form_value_id = this.activatedRoute.snapshot.paramMap.get('form_value_id');
        if (this.form_value_id) {
            setTimeout(() => {
                this.zoomTo(this.form_value_id);
            }, 3000)
        }
    }

    ngOnInit(): void {
        const extent = this.formService.formLayer.getSource().getExtent();
        console.log(extent)
        if (extent && isFinite(extent[0])) {
            this.mapService.map().getView().fit(extent, {
                size: this.mapService.map().getSize(),
                padding: [200, 200, 300, 200],
                duration: 1000
            })
        }
    }

    ngAfterViewInit() {
        if (this.dataSource) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        }
    }

    ngOnDestroy(): void {
        this.layerService.notificationLayer().getSource().clear();
    }

    zoomTo(id): void {
        var source = this.formService.formLayer.getSource();
        var feature = source.getFeatures().find((f) => {
          return f.getProperties().id === id;
        });

        if (feature) {
          var extent = feature.getGeometry().getExtent();
          this.mapService.map().getView().fit(extent, {
            size: this.mapService.map().getSize(),
            padding: [200, 200, 300, 200],
            duration: 1000,
            maxZoom: 15
          });
        } else {
          console.log('Feature met ID ' + id + ' niet gevonden.');
        }
    }

    getItemValue(element, itemId) {
        const item = element.values.find(v => v.item_id === itemId);
        return item ? item.value : '';
    }


    download(value, name): void {
        const encodedValue = btoa(value);
        this.http
            .get(environment.api_base_url + '/download/' + encodedValue, {
                responseType: 'blob'
            })
            .subscribe(x => {
                value = value.substring(value.lastIndexOf('/') + 1);
                FileSaver.saveAs(x, name);
            });
    }

    exportExcel(): void {
        const exportNotifications = this.values
            // .filter(
            //     n =>
            //         n.status === this.filterType() ||
            //         this.filterType() === 'Alles'
            // )
            .map(n => ({
                ...n,
                receivers: n.receivers
                    ? n.receivers
                          .filter(receiver => receiver.user?.email)
                          .map(receiver => receiver.user.email)
                          .join(', ')
                    : ''
            }))
            .map(
                ({
                    id,
                    user_id,
                    config_id,
                    signature,
                    features,
                    files,
                    ...rest
                }) => rest
            );

        // Define the key renaming mappings
        const keyMappings: Record<string, string> = {
            status: 'Status',
            receivers: 'Ontvangers',
            subject: 'Onderwerp',
            message: 'Bericht',
            created_at: 'Aangemaakt op datum',
            updated_at: 'Laatst bewerkt op datum',
            expired_at: 'Verlopen op datum',
            expected_at: 'Verwacht op datum',
            is_mail_send: 'Is de mail verzonden'
        };

        // Rename keys in each notification object
        exportNotifications.forEach(notification => {
            for (const [oldKey, newKey] of Object.entries(keyMappings)) {
                if (notification.hasOwnProperty(oldKey)) {
                    notification[newKey] = notification[oldKey];
                    delete notification[oldKey];
                }
            }
        });

        // Export as Excel
        this.excelService.exportAsExcelFile(exportNotifications, 'Export');
    }

    clearSearchInput() {
        this.searchInput.set('');
    }

    updateSearchInput(value: string): void {
        this.searchInput.set(value);
    }

    openHistoryDialog(id): void {
        const dialogRef = this.dialog.open(HistoryDialogComponent, {
            width: '50%',
            panelClass: 'customStylingDialog',
            data: {
                id
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(result)
            }
        });
    }

    createPageSizeOptions(): void {
        const size = this.total();
        const options = [10, 25, 50, 100, 200];
        const filteredOptions = options.filter(option => option <= size);
        this.pageSizeOptions.set(filteredOptions);
    }

    toggleRow(row) {
        row.expanded = !row.expanded;
      }

    openRow(row) {
        this.zoomTo(row.id)
        this.expandedElement = this.expandedElement === row ? null : row
    }

    applyFilter(value) {
        console.log(value)
        this.dataSource.filter = value.trim().toLowerCase();
    }

    edit(element) {
        this.editService.feature.set(element);
    }
}
