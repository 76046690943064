<div class="edit" *ngIf="forms()">
    <ngx-loading [show]="loading()"></ngx-loading>

    <div class="detailform-content">
        <h4>
            {{ forms().name }}
        </h4>
        <!-- <h4>
            {{ feature.id ? 'Bewerken' : 'Toevoegen' }}
            </h4> -->

        <p>
            {{ forms().description }}
        </p>

        <hr />

        <form (ngSubmit)="save()" [formGroup]="form">
            <fieldset>
                <div *ngFor="let item of forms().form_items" class="row">
                    <div class="col-12" *ngIf="item.type === 'text'">
                        <mat-form-field class="w-100">
                            <mat-label> {{ item.name }} </mat-label>
                            <input
                                matInput
                                [placeholder]="item.placeholder"
                                [formControlName]="
                                    item.value ? item.value : item.id
                                "
                                [value]="
                                    feature()[item.id] ? feature()[item.id] : ''
                                "
                                [required]="item.required"
                            />
                        </mat-form-field>
                    </div>

                    <div class="col-12" *ngIf="item.type === 'number'">
                        <mat-form-field class="w-100">
                            <mat-label> {{ item.name }} </mat-label>
                            <input
                                matInput
                                type="number"
                                [placeholder]="item.placeholder"
                                [formControlName]="
                                    item.value ? item.value : item.id
                                "
                                [value]="
                                    feature()[item.id] ? feature()[item.id] : ''
                                "
                                [required]="item.required"
                            />
                        </mat-form-field>
                    </div>

                    <div class="col-12" *ngIf="item.type === 'date'">
                        <mat-form-field class="w-100">
                            <mat-label> {{ item.name }} </mat-label>
                            <input
                                matInput
                                [matDatepicker]="picker"
                                [placeholder]="item.placeholder"
                                [formControlName]="
                                    item.value ? item.value : item.id
                                "
                                [value]="
                                    feature()[item.id] ? feature()[item.id] : ''
                                "
                                [required]="item.required"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="picker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-12" *ngIf="item.type === 'textarea'">
                        <mat-form-field class="w-100">
                            <mat-label> {{ item.name }} </mat-label>
                            <textarea
                                matInput
                                [placeholder]="item.placeholder"
                                [formControlName]="
                                    item.value ? item.value : item.id
                                "
                                [value]="
                                    feature()[item.id] ? feature()[item.id] : ''
                                "
                                [required]="item.required"
                            ></textarea>
                        </mat-form-field>
                    </div>

                    <div class="col-12" *ngIf="item.type === 'email'">
                        <mat-form-field class="w-100">
                            <mat-label> {{ item.name }} </mat-label>
                            <input
                                matInput
                                type="email"
                                [placeholder]="item.placeholder"
                                [formControlName]="
                                    item.value ? item.value : item.id
                                "
                                [value]="
                                    feature()[item.id] ? feature()[item.id] : ''
                                "
                                [required]="item.required"
                            />
                        </mat-form-field>
                    </div>

                    <div class="col-12" *ngIf="item.type === 'checkbox'">
                        <mat-checkbox
                            color="primary"
                            [formControlName]="
                                item.value ? item.value : item.id
                            "
                            [required]="item.required"
                        >
                            {{ item.name }}
                        </mat-checkbox>
                    </div>

                    <div class="col-12" *ngIf="item.type === 'select'">
                        <mat-form-field class="w-100">
                            <mat-label> {{ item.name }} </mat-label>
                            <mat-select
                                [formControlName]="
                                    item.value ? item.value : item.id
                                "
                                [value]="
                                    feature()[item.id] ? feature()[item.id] : ''
                                "
                                [required]="item.required"
                            >
                                <ng-container
                                    *ngFor="let option of item.selects"
                                >
                                    <mat-option
                                        *ngIf="
                                            step === option.step || !option.step
                                        "
                                        [value]="option.value"
                                    >
                                        {{ option.value }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-12" *ngIf="item.type === 'pdf'">
                        <div
                            *ngIf="
                                !feature()[item.value ? item.value : item.id]
                            "
                        >
                            <p>PDF Toevoegen</p>
                            <div
                                class="drag-drop-area"
                                (dragover)="onDragOver($event)"
                                (dragleave)="onDragLeave($event)"
                                (drop)="
                                    onDrop(
                                        $event,
                                        item.value ? item.value : item.id
                                    )
                                "
                            >
                                <input
                                    type="file"
                                    class="form-control"
                                    #reader
                                    name="Upload"
                                    id="fileUpload"
                                    accept=".pdf"
                                    (change)="
                                        uploadListener(
                                            $event,
                                            item.value ? item.value : item.id
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div
                            *ngIf="feature()[item.value ? item.value : item.id]"
                        >
                            <embed
                                [src]="
                                    sanitizer.bypassSecurityTrustResourceUrl(
                                        environment.api_base_url +
                                            '/fileurl/' +
                                            feature()[
                                                item.value
                                                    ? item.value
                                                    : item.id
                                            ]
                                    )
                                "
                                width="300"
                                height="450"
                                type="application/pdf"
                            />
                            <a
                                class="link-primary"
                                (click)="
                                    deleteFile(
                                        item.value ? item.value : item.id
                                    )
                                "
                                >Verwijder PDF</a
                            >
                        </div>
                    </div>
                    <br />
                    <br />

                    <div class="col-12" *ngIf="item.type === 'image'">
                        <input
                            type="number"
                            *ngIf="feature()"
                            [formControlName]="
                                item.value ? item.value : item.id
                            "
                            [value]="
                                feature()[item.value ? item.value : item.id]
                            "
                            hidden="true"
                        />

                        <div
                            *ngIf="
                                !feature()[item.value ? item.value : item.id]
                            "
                        >
                            <p>Afbeelding toevoegen</p>
                            <div
                                class="drag-drop-area"
                                (dragover)="onDragOver($event)"
                                (dragleave)="onDragLeave($event)"
                                (drop)="
                                    onDrop(
                                        $event,
                                        item.value ? item.value : item.id
                                    )
                                "
                            >
                                <input
                                    type="file"
                                    class="form-control"
                                    #reader
                                    name="Upload"
                                    id="fileUpload"
                                    accept="image/*"
                                    capture="environment"
                                    (change)="
                                        uploadListener(
                                            $event,
                                            item.value ? item.value : item.id
                                        )
                                    "
                                />
                            </div>
                            <mat-progress-bar
                                mode="determinate"
                                [value]="
                                    uploadProgresses[
                                        item.value ? item.value : item.id
                                    ] ?? 0
                                "
                            ></mat-progress-bar>
                            <p
                                *ngIf="
                                    uploadProgresses[
                                        item.value ? item.value : item.id
                                    ] === 100
                                "
                            >
                                Upload voltooid
                            </p>
                        </div>
                        <div
                            *ngIf="feature()[item.value ? item.value : item.id]"
                        >
                            <img
                                class="mw-100"
                                [src]="
                                    sanitizer.bypassSecurityTrustResourceUrl(
                                        environment.api_base_url +
                                            '/fileurl/' +
                                            feature()[
                                                item.value
                                                    ? item.value
                                                    : item.id
                                            ]
                                    )
                                "
                                alt="Beschrijving van de afbeelding"
                            />
                            <a
                                class="link-primary"
                                (click)="
                                    deleteFile(
                                        item.value ? item.value : item.id
                                    )
                                "
                                >Verwijder afbeelding</a
                            >
                        </div>
                    </div>
                </div>
                <br />
                <br />

                <div
                    *ngIf="
                        !editService.feature() ||
                        (editService.feature() &&
                            !editService.feature().get('id'))
                    "
                >
                    <h5 class="text-center">Geometrie tekenen</h5>
                    <mat-button-toggle-group
                        class="w-75 row geometry"
                        [(ngModel)]="geomType"
                        [ngModelOptions]="{ standalone: true }"
                        (click)="createFeature(layerService.drawingLayer())"
                        hideSingleSelectionIndicator="true"
                    >
                        <mat-button-toggle
                            [class.active]="
                                interactionService.create.active &&
                                geomType === 'Polygon'
                            "
                            value="Polygon"
                            class="col-3"
                            matTooltip="Polygon"
                            ><svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 100 100"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                aria-hidden="true"
                                role="img"
                                class="iconify iconify--gis"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M32.5 10.95c-6.89 0-12.55 5.66-12.55 12.55c0 4.02 1.935 7.613 4.91 9.916L14.815 54.172a12.354 12.354 0 0 0-2.316-.223C5.61 53.95-.05 59.61-.05 66.5c0 6.89 5.66 12.55 12.55 12.55c5.13 0 9.54-3.151 11.463-7.603l51.277 7.71c1.232 5.629 6.281 9.894 12.26 9.894c6.656 0 12.114-5.297 12.48-11.867a3.5 3.5 0 0 0 .07-.684a3.5 3.5 0 0 0-.071-.7c-.375-6.562-5.829-11.85-12.479-11.85c-.134 0-.264.015-.396.019L80.242 43.05c3.275-2.127 5.509-5.746 5.738-9.867a3.5 3.5 0 0 0 .07-.684a3.5 3.5 0 0 0-.071-.7c-.375-6.562-5.829-11.85-12.479-11.85c-5.062 0-9.452 3.06-11.43 7.415l-17.082-4.517a3.5 3.5 0 0 0-.01-.047c-.374-6.563-5.828-11.852-12.478-11.852zm0 7c3.107 0 5.55 2.443 5.55 5.55c0 3.107-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.55 5.55-5.55zm41 9c3.107 0 5.55 2.443 5.55 5.55c0 3.107-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.55 5.55-5.55zm-30.137 2.708l17.739 4.69C62.007 40.37 67.239 45.05 73.5 45.05l.033-.002l6.92 21.092a12.688 12.688 0 0 0-4.705 6.015l-50.916-7.654a12.611 12.611 0 0 0-3.787-7.13l10.342-21.378c.368.033.737.057 1.113.057c4.652 0 8.71-2.592 10.863-6.393zM12.5 60.95c3.107 0 5.55 2.444 5.55 5.551s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.55 5.55-5.55zm75 10c3.107 0 5.55 2.444 5.55 5.551s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.55 5.55-5.55z"
                                    fill="#000000"
                                ></path>
                            </svg>
                        </mat-button-toggle>
                        <mat-button-toggle
                            [class.active]="
                                interactionService.create.active &&
                                geomType === 'LineString'
                            "
                            value="LineString"
                            class="col-3"
                            matTooltip="Lijn"
                            ><svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M18 5C17.4477 5 17 5.44772 17 6C17 6.27642 17.1108 6.52505 17.2929 6.70711C17.475 6.88917 17.7236 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5ZM15 6C15 4.34315 16.3431 3 18 3C19.6569 3 21 4.34315 21 6C21 7.65685 19.6569 9 18 9C17.5372 9 17.0984 8.8948 16.7068 8.70744L8.70744 16.7068C8.8948 17.0984 9 17.5372 9 18C9 19.6569 7.65685 21 6 21C4.34315 21 3 19.6569 3 18C3 16.3431 4.34315 15 6 15C6.46278 15 6.90157 15.1052 7.29323 15.2926L15.2926 7.29323C15.1052 6.90157 15 6.46278 15 6ZM6 17C5.44772 17 5 17.4477 5 18C5 18.5523 5.44772 19 6 19C6.55228 19 7 18.5523 7 18C7 17.7236 6.88917 17.475 6.70711 17.2929C6.52505 17.1108 6.27642 17 6 17Z"
                                    fill="#000000"
                                />
                            </svg>
                        </mat-button-toggle>
                        <mat-button-toggle
                            [class.active]="
                                interactionService.create.active &&
                                geomType === 'Point'
                            "
                            value="Point"
                            class="col-3"
                            matTooltip="Punt"
                            ><svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 100 100"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                aria-hidden="true"
                                role="img"
                                class="iconify iconify--gis"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                                    fill="#000000"
                                ></path>
                            </svg>
                        </mat-button-toggle>
                        <mat-button-toggle
                            [class.active]="
                                interactionService.create.active &&
                                geomType === 'Copy'
                            "
                            value="Copy"
                            class="col-3"
                            matTooltip="Bestaande geometrie kopiëren"
                            ><fa-icon [icon]="faCopy"></fa-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>

                <div class="row mt-3 ms-3 me-3">
                    <hr />

                    <button
                        *ngIf="
                            (editService.feature() &&
                                editService.feature().get('id')) ||
                            forms().type === 'geoserver'
                        "
                        class="col"
                        type="button"
                        mat-stroked-button
                        color="warn"
                        (click)="delete()"
                    >
                        Verwijderen
                    </button>

                    <button
                        class="col ms-1"
                        type="button"
                        mat-stroked-button
                        color="accent"
                        (click)="cancel()"
                    >
                        Annuleren
                    </button>

                    <button
                        mat-stroked-button
                        class="col ms-1"
                        type="submit"
                        [disabled]="
                            (!layerService
                                .drawingLayer()
                                .getSource()
                                .getFeatures().length &&
                                !editService.feature()) ||
                            !form.valid
                        "
                        color="primary"
                    >
                        Opslaan
                    </button>
                </div>
            </fieldset>
        </form>
    </div>
</div>
