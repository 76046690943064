<div class="notifications">
    <div class="my-3">
        <h4>Registraties</h4>
    </div>
    <div class="row d-flex">
        <mat-form-field class="col">
            <input
                matInput
                type="text"
                placeholder="Zoeken"
                (input)="applyFilter($event.target.value)"
            />
        </mat-form-field>
        <mat-form-field class="col">
            <mat-select
                (selectionChange)="applyFilter($event.value)"
                placeholder="Status"
            >
                <mat-option
                    *ngFor="let select of filterOptions"
                    [value]="select.value"
                >
                    {{ select.value }}</mat-option
                >
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="dataSource">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            multiTemplateDataRows
            class="mat-elevation-z8 px-2"
        >
            <ng-container matColumnDef="created_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Aangemaakt door
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-template *ngIf="element.user">
                        {{ element.user.first_name
                        }}{{ element.user.middle_name }}
                        {{ element.user.last_name }}
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="updated_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Laatst aangepast op
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.created_at | date }}
                </td>
            </ng-container>

            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">
                    &nbsp;
                </th>
                <td mat-cell *matCellDef="let element">
                    <button
                        mat-icon-button
                        aria-label="expand row"
                        (click)="
                            expandedElement =
                                expandedElement === element ? null : element;
                            $event.stopPropagation()
                        "
                    >
                        @if (expandedElement === element) {
                        <mat-icon>keyboard_arrow_up</mat-icon>
                        } @else {
                        <mat-icon>keyboard_arrow_down</mat-icon>
                        }
                    </button>
                </td>
            </ng-container>

            <!-- Expandable Row Content -->
            <ng-container matColumnDef="expandDetail">
                <td mat-cell *matCellDef="let element" colspan="4">
                    <div
                        class="element-detail"
                        [@expand]="
                            element === expandedElement
                                ? 'expanded'
                                : 'collapsed'
                        "
                    >
                        <div *ngFor="let item of form.form_items">
                            {{ item.name }}:
                            <span>{{ getItemValue(element, item.id) }}</span>
                        </div>

                        <button
                            mat-button
                            (click)="openHistoryDialog(element.id)"
                            class="light-icon"
                        >
                            Geschiedenis
                        </button>
                        <button
                            mat-button
                            (click)="edit(element)"
                            class="light-icon"
                            [disabled]="!element.can_edit"
                        >
                            Bijwerken
                        </button>
                        <button mat-button disabled class="light-icon">
                            Commentaar
                        </button>
                    </div>
                </td>
            </ng-container>

            <!-- Row Content -->
            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsWithExpand"
            ></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsWithExpand"
                [class.example-expanded-row]="expandedElement === row"
                (click)="openRow(row)"
            ></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: ['expandDetail']"
                class="example-detail-row"
            ></tr>
        </table>

        <mat-paginator
            [length]="total()"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
        >
        </mat-paginator>
    </div>
</div>
